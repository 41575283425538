export default function reloadFrame(targets, template, { streamElement }) {
  const frame = document.getElementById(streamElement.dataset?.frameId);

  if (frame) {
    frame.addEventListener(
      'turbo:frame-load',
      () => {
        const articleElement = frame.querySelector('article.card');
        if (articleElement) {
          articleElement.classList.add('amba-card--new-activity');
        }
      },
      { once: true }
    );

    frame.reload();
  }
}
