if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js', { scope: '/' })
    .then(() => {
      console.log('Service worker registered');

      return;
    })
    .catch((error) => {
      window.appsignal.sendError(`Error registering service worker: ${error}`);
    });
}
