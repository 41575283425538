const NATIVE_ACTION_NAMES = [
  'append',
  'prepend',
  'replace',
  'update',
  'remove',
  'before',
  'after',
];

/**
 * @callback TurboStreamAction
 * @param {Element} target - The element to update
 * @param {TemplateElement} template - The template element inside the `<turbo-stream>`
 * @param {Element} options.streamElement - The `<turbo-stream>` element itself, allowing to grab extra info (from `data-...` attributes, for example)
 * @param {Event} options.event - The `before-stream-render` event, allowing to `preventDefault` if necessary
 */
/**
 * Register extra actions for `<turbo-stream>` tags,
 * to be used for providing new actions or intercept
 * calls to existing ones.
 *
 * @param {Object.<string,TurboStreamAction>} actions - A name => function hash of actions
 */
export default function setupCustomTurboStreamActions(actions = {}) {
  document.addEventListener('turbo:before-stream-render', function (event) {
    const streamElement = event.target;
    const actionName = streamElement.getAttribute('action');
    const action = actions[actionName];

    if (action) {
      // Turbo will throw an error if it doesn't recognise
      // the action name, so we need to
      if (!NATIVE_ACTION_NAMES.includes(actionName)) {
        event.preventDefault();
      }

      const template = streamElement.firstElementChild;

      // Skip a frame to match TurboStream's lifecycle
      // ensuring that actions happen with the right timing
      // Only look up targets when actively running,
      // allowing to get the freshest targets on the page
      // (for example, if another action replaced some elements)
      requestAnimationFrame(() => {
        targets(streamElement).forEach((target) => {
          action(target, template, {
            streamElement,
            event,
          });
        });
      });
    }
  });
}

export function targets(turboStreamElement) {
  if (turboStreamElement.hasAttribute('target')) {
    return [document.getElementById(turboStreamElement.getAttribute('target'))];
  } else {
    return [
      ...document.querySelectorAll(turboStreamElement.getAttribute('targets')),
    ];
  }
}

export function target(turboStreamElement) {
  if (turboStreamElement.hasAttribute('target')) {
    return document.getElementById(turboStreamElement.getAttribute('target'));
  } else {
    return document.querySelector(turboStreamElement.getAttribute('targets'));
  }
}
