/**
 * Bootstrap only adds a `modal-open` class to the `<body>`.
 * It's useful, but doesn't let you know which modal is open,
 * for when you need custom transitions for the modal entrance/exit
 *
 * This adds a couple of event listeners that set a data attribute on the body
 * allowing to hook CSS transitions when the modal enters or leaves the page
 */

document.addEventListener('show.bs.modal', function (event) {
  // Another listener may have cancelled the event
  if (!event.defaultPrevented) {
    // Skip a frame so Bootstrap has swapped the modal to `display: block`,
    // allowing CSS transitions to execute appropriately
    requestAnimationFrame(() => {
      document.body.setAttribute('data-open-modal', event.target.id);
    });
  }
});
document.addEventListener('hidden.bs.modal', function (event) {
  if (!event.defaultPrevented) {
    document.body.removeAttribute('data-open-modal', event.target.id);
  }
});
