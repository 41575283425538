export default function updateControllerAttribute(
  targets,
  template,
  { streamElement }
) {
  const controllerElement = document.getElementById(
    streamElement.dataset.controllerElementId
  );
  const valueAttributeName = streamElement.dataset.valueAttributeName;
  const newValue = streamElement.dataset.value;

  if (controllerElement) {
    controllerElement.setAttribute(valueAttributeName, newValue);
  } else {
    window.appsignal.sendError(
      `:updateControllerAttribute stream error. Controller element with id: ${streamElement.dataset.controllerElementId} couldn't be found`
    );
  }
}
