/**
 * Enhances Turbo frames with an `amba:fetch-error` event
 * allowing to:
 *  - provide users with feedback on network failures
 *  - harmonize handling of network and server-returned errors
 *
 * Also adds a `data-response-error` attribute to hook some specific styles
 */

// Dispatch event for network failures
document.addEventListener('turbo:fetch-request-error', (event) => {
  dispatchFetchError(event.target, {
    eventFromTurbo: event,
  });
});

// For harmonized error handling we'll also dispatch a fetch-error event
// if the server responds with a 4XX or 5xx status.
// The original event is made available to recipient so that handling code
// can prevent Turbo from handling the response by calling `.preventDefault()`
// on the original `turbo:before-fetch-response` event.
document.addEventListener('turbo:before-fetch-response', (event) => {
  if (!event.detail.fetchResponse.response.ok) {
    dispatchFetchError(event.target, {
      eventFromTurbo: event,
    });
  }
});

function dispatchFetchError(turboFrameElement, detail) {
  turboFrameElement.dispatchEvent(
    new CustomEvent('amba:fetch-error', {
      bubbles: true,
      cancelable: true,
      detail,
    })
  );
}

// Mark frames with errors for fetching
document.addEventListener('amba:fetch-error', (event) => {
  event.target.setAttribute('data-response-error', '');
});

// Remove error attribute on re-request
document.addEventListener('turbo:before-fetch-request', (event) => {
  event.target.removeAttribute('data-response-error');
});
