const CLASS_TILE_ALERT = 'amba-tile-component--alert';
const CLASS_TILE_OK = 'amba-tile-component--ok';

export default function updateTileNotch(targets, template, { streamElement }) {
  const tileClass = streamElement.dataset.vipTileClass;
  const tiles = document.querySelectorAll(`.${tileClass}`);

  if (tiles.length) {
    tiles.forEach((tile) => {
      tile.classList.remove(CLASS_TILE_ALERT);
      tile.classList.add(CLASS_TILE_OK);
    });
  }
}
