import { announce } from '../../../a11y/announcement';

export default function (target, template) {
  target.innerHTML = '';
  // Delay the announcement to give the modal time to close
  // Without it, announcement gets ignored for some reason.
  //
  // Theory is that any change made behind the modal doesn't
  // get conveyed to assistive tech while the modal is open.
  // Needs confirmation and workaround if that's the case
  setTimeout(() => {
    announce(template.innerHTML, { region: target });
  }, 500);
}
