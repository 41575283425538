/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require.context('../images', true);
require.context('../audio', true);

document.documentElement.classList.remove('no-js');

import setupCustomTurboStreamActions from '../javascript/lib/turbo/stream/custom_actions';
import morphdom from '../javascript/lib/turbo/stream/custom_actions/morphdom';
import focus from '../javascript/lib/turbo/stream/custom_actions/focus';
import announce from '../javascript/lib/turbo/stream/custom_actions/announce';
import reload from '../javascript/lib/turbo/stream/custom_actions/reload';
import redirect from '../javascript/lib/turbo/stream/custom_actions/redirect';
import updateControllerAttribute from '../javascript/lib/turbo/stream/custom_actions/update_controller_attribute';
import updateTileNotch from '../javascript/lib/turbo/stream/custom_actions/update_tile_notch';
import reloadFrame from '../javascript/lib/turbo/stream/custom_actions/reload_frame';
import '../javascript/lib/register_service_worker';
import '../javascript/lib/turboBeforeCache';
import '../javascript/lib/turbo/fetch_errors';
import '../javascript/lib/turbo/submit_success';
import '../javascript/lib/bootstrap/track_open_modal';
import 'trix';
import '@rails/actiontext';

setupCustomTurboStreamActions({
  morphdom,
  focus,
  announce,
  reload,
  redirect,
  updateControllerAttribute,
  updateTileNotch,
  reloadFrame,
});
