/**
 *  Differenciate successful Turbo submissions with a `turbo:submit-success`
 */
document.addEventListener('turbo:submit-end', (event) => {
  if (event.detail.success) {
    event.target.dispatchEvent(
      new CustomEvent('turbo:submit-success', {
        bubbles: true,
        cancelable: true,
        detail: event.detail,
      })
    );
  }
  // If the `fetch-error` event is insufficient to distinguish
  // submission errors, this could be the place to send a `submit-error`
});
